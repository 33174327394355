.navbar {
  display: flex;
  justify-content: space-between; /* Space between left and right sections */
  align-items: center;
  background-color: #333; /* Adjusted to a deep blue background */
  padding: 8px 15px; /* Reduced padding for a smaller navbar */
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: padding 0.3s ease, background-color 0.3s ease;
  font-size: 0.9rem; /* Slightly smaller font size */
}

.navbar.scrolled {
  padding: 5px 15px; /* Further reduce padding when scrolled */
  background-color: #222; /* Darker background when scrolled */
  font-size: 0.8rem; /* Even smaller font size when scrolled */
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-name {
  color: white;
  font-weight: bold;
  margin-left: 10px; /* Space between name and logo */
}

.navbar-logo {
  height: 40px; /* Reduced logo size */
  margin-right: 15px;
  border-radius: 2px;
  transition: opacity 0.3s ease, height 0.3s ease;
}

.navbar.scrolled .navbar-logo {
  height: 0; /* Hide logo when scrolled */
  opacity: 0;
}

.navbar-list {
  list-style-type: none; /* Removes bullet points from the navbar list */
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-item {
  position: relative;
  margin-right: 25px; /* Slightly increased space between navbar items */
}

.navbar-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  padding: 8px 12px; /* Slightly reduced padding for links */
  display: block;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center; /* Center the text */
}

.navbar-link:hover {
  background-color: #555;
  border-radius: 5px;
}

/* Redesigned Dropdown */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0; /* Align to the left edge of the parent */
  background-color: #fff;
  border: 1px solid #ddd;
  min-width: 70px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(10px);
  visibility: hidden;
  text-align: center;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
}

.navbar-item.dropdown:hover .dropdown-content {
  display: block;
  opacity: 1;
  transform: translateY(0); /* Animates dropdown into place */
  visibility: visible;
}


.dropdown-content li {
  list-style-type: none; /* Removes bullet points from dropdown items */
  padding: 5px 0; /* Adjust padding to center the text and reduce white space */
  margin: 0; /* Remove any default margins */
  border-bottom: 1px solid #eee; /* Light gray divider between items */
}

.dropdown-content li:last-child {
  border-bottom: none; /* No border on last item */
}

.dropdown-content li a {
  color: #333; /* Darker text for better readability */
  text-decoration: none;
  display: block; /* Ensures the entire area is clickable */
  padding: 8px 12px; /* Reduced padding to remove white space */
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center; /* Center the text */
}

.dropdown-content li a:hover {
  background-color: #f1f1f1; /* Subtle hover effect */
  color: #0c2a6f; /* Matches the navbar's base color on hover */
  border-radius: 0; /* Keeps the hover effect flat */
}
