.research-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.research-title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.research-intro {
    font-size: 18px;
    text-align: center;
    margin-bottom: 40px;
    color: #555;
}

.articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.article-card {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    cursor: pointer; /* Make the card look clickable */
}

.article-card:hover {
    transform: translateY(-5px);
}

.article-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.article-summary {
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
}

.article-link {
    text-decoration: none;
    color: inherit; /* Ensures link inherits the color of the parent */
    display: block; /* Ensures the entire card is clickable */
}

.article-link:hover .article-card {
    transform: translateY(-5px); /* Apply hover effect when the link is hovered */
}

.read-more {
    font-weight: bold;
    color: #007bff;
    margin-top: 15px;
}
