.article-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
  }
  
  .article-container h1 {
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .article-container h2 {
    font-size: 28px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .article-container p {
    margin-bottom: 20px;
    color: #333;
    font-size: 18px;
  }
  
  .article-container em {
    font-size: 16px;
    color: #666;
  }
  
  .article-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .article-container a:hover {
    text-decoration: underline;
  }
  